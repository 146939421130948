<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Order List
      </h3>
      <hr class="flex-1 border-primary mx-4" />
    </div>

    <div class="flex flex-wrap w-full">
      <DataTable
          class="w-full"
          stripedRows
          :sortable="true"
          :loading="isLoading"
          :value="tableData"
          :paginator="true"
          :rows="rowsPerPage"
          :totalRecords="totalRecords"
          sortField="order_date"
          sortOrder="-1"
          selectionMode="single"
          @row-select="onRowSelect"
          @row-click="onRowClick"
          @page="onPageChange"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[1, 2, 5, 10, 20]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :lazy="false"
      >
        <!-- Standard columns -->
        <Column
            v-for="col in columns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            :sortable="col.sortable"
            :headerStyle="{ width: col.width }"
        >
          <template v-if="col.custom" #body="slotProps">
            <!-- Sold To Contact -->
            <template v-if="col.slotName === 'sold-to-slot'">
              <div>
                {{ slotProps.data.sold_to_contact ? slotProps.data.sold_to_contact.name : 'Not Available' }}
              </div>
            </template>

            <!-- order date slot -->
            <template v-if="col.slotName === 'order-status-slot'">
              <div>
                {{ checkStatusAndReturnUppercase(slotProps.data.status) || 'Status Unknown' }}
              </div>
            </template>

            <!-- Invoice To Contact -->
            <template v-if="col.slotName === 'invoice-to-slot'">
              <div>
                {{ slotProps.data.invoice_to_contact ? slotProps.data.invoice_to_contact.name : 'Not Available' }}
              </div>
            </template>

            <!-- Delivery To Contact -->
            <template v-if="col.slotName === 'delivery-to-slot'">
              <div>
                {{ slotProps.data.delivery_to_contact ? slotProps.data.delivery_to_contact.name : 'Not Available' }}
              </div>
            </template>


            <!-- Order Date -->
            <template v-if="col.slotName === 'order-date-slot'">
              <div>
                {{ formatTimestamp(slotProps.data.order_date) || 'Date Unknown' }}
              </div>
            </template>

            <template v-if="col.slotName === 'total-order-cost-slot'">
              <div>
                £{{ formatCurrency(slotProps.data.total_order_cost) }}
              </div>
            </template>

          </template>
        </Column>

        <template #empty>
          No orders found.
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contactId: String,
  },
  data() {
    return {
      isLoading: false,
      tableData: [],
      rowsPerPage: 10,
      totalRecords: 0,
      sortField: 'order_no',
      sortOrder: 1,
      columns: [
        { field: 'sales_order_no', header: 'Order No', sortable: true, width: '10%' },
        { field: 'status', header: 'Status', sortable: true, width: '10%',  custom: true,
          slotName: 'order-status-slot', },
        {
          field: 'order_date',
          header: 'Order Date',
          sortable: true,
          width: '10%',
          custom: true,
          slotName: 'order-date-slot',
        },
        {
          field: 'sold_to_contact.name',
          header: 'Sold To',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: 'sold-to-slot',
        },
        {
          field: 'invoice_to_contact.name',
          header: 'Invoice To',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: 'invoice-to-slot',
        },
        {
          field: 'delivery_to_contact.name',
          header: 'Delivery To',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: 'delivery-to-slot',
        },
        { field: 'invoice_no', header: 'Invoice No', sortable: true, width: '10%' },
        {
          field: 'total_order_cost',
          header: 'Value',
          sortable: true,
          width: '15%',
          custom: true,
          slotName: 'total-order-cost-slot',
        },
      ],
    };
  },
  methods: {
    onRowSelect(event) {
      console.log('Row selected:', event.data);
    },
    async onRowClick(event) {
      if (event.data && event.data.sales_order_id) {
        await this.$router.push({
          name: 'sales-edit',
          params: { sales_order_id: event.data.sales_order_id },
        });
      }
    },
    onPageChange(event) {
      console.log('Page changed:', event.page);
    },
    async fetchInitialData() {
      this.isLoading = true;
      try {
        const orders = await this.SalesOrderService.getSalesOrdersByContactId(this.contactId);
        this.tableData = orders;
        this.totalRecords = orders.length;
      } catch (error) {
        console.error('Error fetching sales orders:', error);
      } finally {
        this.isLoading = false;
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    formatCurrency(value) {
      return parseFloat(value).toFixed(2);
    },
    checkStatusAndReturnUppercase(status) {

      if (status && status.toLowerCase() === 'items added') {
        return 'OPEN';
      }

      if (status && status.toLowerCase() === 'confirmed') {
        return 'OPEN';
      }

      return status ? status.toUpperCase() : 'N/A';
    },
  },
  async mounted() {
    await this.fetchInitialData();
  },
};
</script>
